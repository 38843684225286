import { inject, Injectable } from '@angular/core';
import { RequesterService } from './requester.service';
import { SERVICE_CONFIG } from '../constants';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { ExternalTokenInterface, IDHubTokenInterface } from '../interfaces/token.interface';
import { map, takeUntil } from 'rxjs/operators';
import { TOKEN } from '../constants/token.constant';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppConfigService } from '../../app.config.service';
import { environment } from '../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RedirectType } from '../enums/redirect-type.enum';
import { ScenarioState } from '../enums/scenario.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  stopInterval$: Subject<boolean> = new Subject<boolean>();
  timeToExpiration$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  sessionExpireMessageKey = 'warning.sessionExpiredIn';
  showBadUrlErrorIfExists = true;

  private readonly requesterService = inject(RequesterService);
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  private readonly appConfigService = inject(AppConfigService);
  private readonly isDesktop = inject(DeviceDetectorService).isDesktop();

  auth(
    oneTimeToken: string,
    processType: string
  ): Observable<ExternalTokenInterface | IDHubTokenInterface> {
    return this.requesterService.request(
      SERVICE_CONFIG.auth,
      {
        oneTimeToken,
        processType,
      },
      null,
      {
        skipToken: true,
      }
    );
  }

  setToken(token: string, external: boolean): void {
    this.requesterService.setToken(token, external);
  }

  serveOneTimeToken(
    ott: string,
    processType: string,
    path: string,
    external: boolean
  ): Observable<string | null> {
    return this.auth(ott, processType).pipe(
      map((tokenResponse) => {
        const tokenValue = tokenResponse.webToken.id;
        this.setToken(tokenValue, external);
        this.setLocalStorage(tokenResponse, external);
        this.location.go(this.router.createUrlTree([path]).toString());
        return tokenValue;
      })
    );
  }

  setLocalStorage(token: IDHubTokenInterface | ExternalTokenInterface, external: boolean) {
    const exp = token.expiringAt.toString();
    if (external) {
      localStorage.setItem(TOKEN.EXTERNAL_JWT, token.webToken.id);
      localStorage.setItem(TOKEN.EXTERNAL_EXPIRING_AT, exp);

      const t: ExternalTokenInterface = token as ExternalTokenInterface;

      if (t.failSafeRedirectUrl && t.failSafeRedirectUrl.url) {
        localStorage.setItem(TOKEN.EXTERNAL_SUCCESS_REDIRECT_URL, t.failSafeRedirectUrl.url);
        localStorage.setItem(TOKEN.EXTERNAL_FAILURE_REDIRECT_URL, t.failSafeRedirectUrl.url);
        localStorage.setItem(TOKEN.EXTERNAL_ERROR_REDIRECT_URL, t.failSafeRedirectUrl.url);
      }
    } else {
      localStorage.setItem(TOKEN.JWT, token.webToken.id);
      localStorage.setItem(TOKEN.EXPIRING_AT, exp);

      const t: IDHubTokenInterface = token as IDHubTokenInterface;

      if (t.failureRedirectUrl && t.failureRedirectUrl.url) {
        localStorage.setItem(TOKEN.FAILURE_REDIRECT_URL, t.failureRedirectUrl.url);
      }
      if (t.successRedirectUrl && t.successRedirectUrl.url) {
        localStorage.setItem(TOKEN.SUCCESS_REDIRECT_URL, t.successRedirectUrl.url);
      }
      if (t.errorRedirectUrl && t.errorRedirectUrl.url) {
        localStorage.setItem(TOKEN.ERROR_REDIRECT_URL, t.errorRedirectUrl.url);
      }

      if (t.displayParameters) {
        localStorage.setItem(
          TOKEN.SUCCESS_WINDOW_SHOW,
          this.setDisplayParamTokenValue(t.displayParameters.SUCCESS_WINDOW_SHOW)
        );
        localStorage.setItem(
          TOKEN.FAILURE_WINDOW_SHOW,
          this.setDisplayParamTokenValue(t.displayParameters.FAILURE_WINDOW_SHOW)
        );
        localStorage.setItem(
          TOKEN.ERROR_WINDOW_SHOW,
          this.setDisplayParamTokenValue(t.displayParameters.ERROR_WINDOW_SHOW)
        );
      }
    }
    this.serveTime(external);
  }

  clearToken(external: boolean) {
    for (const key of Object.keys(TOKEN)) {
      if (!localStorage.length) {
        break;
      }
      const tokenValue = TOKEN[key];
      if (external) {
        if (key.startsWith('EXTERNAL')) {
          localStorage.removeItem(tokenValue);
        }
      } else {
        if (!key.startsWith('EXTERNAL')) {
          localStorage.removeItem(tokenValue);
        }
      }
    }
  }

  redirectToType(type: RedirectType, external: boolean) {
    const redirectUrl = this.getRedirectUrl(type, external);
    if (redirectUrl) {
      this.redirect(external, redirectUrl);
    }
  }

  redirectByScenarioState(state: ScenarioState): void {
    switch (state) {
      case ScenarioState.SCENARIO_SUCCESS:
        return this.redirectToType(RedirectType.success, false);
      case ScenarioState.SCENARIO_ERROR:
        return this.redirectToType(RedirectType.error, false);
      case ScenarioState.SCENARIO_FAILED:
        return this.redirectToType(RedirectType.failure, false);
      default:
        return;
    }
  }

  getStepVisibility(state: ScenarioState): boolean {
    switch (state) {
      case ScenarioState.SCENARIO_SUCCESS:
        return localStorage.getItem(TOKEN.SUCCESS_WINDOW_SHOW) === '1';
      case ScenarioState.SCENARIO_ERROR:
        return localStorage.getItem(TOKEN.ERROR_WINDOW_SHOW) === '1';
      case ScenarioState.SCENARIO_FAILED:
        return localStorage.getItem(TOKEN.FAILURE_WINDOW_SHOW) === '1';
      default:
        return true;
    }
  }

  getRedirectUrl(type: RedirectType, external: boolean): string | null {
    if (external) {
      switch (type) {
        case RedirectType.success:
          return localStorage.getItem(TOKEN.EXTERNAL_SUCCESS_REDIRECT_URL);
        case RedirectType.error:
          return localStorage.getItem(TOKEN.EXTERNAL_ERROR_REDIRECT_URL);
        case RedirectType.failure:
          return localStorage.getItem(TOKEN.EXTERNAL_FAILURE_REDIRECT_URL);
        default:
          return null;
      }
    }
    switch (type) {
      case RedirectType.success:
        return localStorage.getItem(TOKEN.SUCCESS_REDIRECT_URL);
      case RedirectType.error:
        return localStorage.getItem(TOKEN.ERROR_REDIRECT_URL);
      case RedirectType.failure:
        return localStorage.getItem(TOKEN.FAILURE_REDIRECT_URL);
      default:
        return null;
    }
  }

  serveStoredWebToken(external: boolean, refresh = false): Observable<string | null> {
    const tokenKey = external ? TOKEN.EXTERNAL_JWT : TOKEN.JWT;
    const jwt = localStorage.getItem(tokenKey);
    if (jwt) {
      if (!refresh) {
        this.serveTime(external);
      }
      this.setToken(jwt, external);
      return of(jwt);
    }
    return of(null);
  }

  private countDiff(endTime: number) {
    return Math.floor((endTime - new Date().getTime()) / 1000);
  }

  private serveTime(external: boolean) {
    const expKey = external ? TOKEN.EXTERNAL_EXPIRING_AT : TOKEN.EXPIRING_AT;
    const expValue = Number(localStorage.getItem(expKey));

    if (expValue) {
      const end = new Date(expValue);
      const endTime = end.getTime();
      let diffInSeconds = this.countDiff(endTime);

      if (diffInSeconds <= 0) {
        this.redirectToType(RedirectType.error, external);
      }

      interval(1000)
        .pipe(
          takeUntil(this.stopInterval$),
          map(() => {
            diffInSeconds = this.countDiff(endTime);
            if (diffInSeconds <= 0) {
              this.showBadUrlErrorIfExists = false;
              this.stopInterval$.next(true);
              return;
            }

            const timeToExpirationVisible =
              diffInSeconds <= this.appConfigService.secondsToShowSessionInfo;

            if (!environment.production) {
              // tslint:disable-next-line:no-console
              console.log(
                'pozostały czas w sekundach',
                diffInSeconds,
                ' teraz: ',
                new Date().toLocaleTimeString(),
                ' koniec: ',
                end.toLocaleTimeString(),
                ' token: ',
                expValue,
                ' toaster widoczny: ',
                timeToExpirationVisible
              );
            }

            if (timeToExpirationVisible) {
              this.timeToExpiration$.next(diffInSeconds);
            }
          })
        )
        .subscribe({
          complete: () => {
            this.redirectToType(RedirectType.error, external);
          },
        });
    }
  }

  private redirect(external: boolean, redirectUrl: string | null) {
    this.clearToken(external);
    this.stopInterval$.unsubscribe();
    if (redirectUrl && this.isDesktop) {
      window.location.href = redirectUrl;
    }
  }

  private setDisplayParamTokenValue(param: 'VISIBLE' | 'HIDDEN'): string {
    return param === 'VISIBLE' ? '1' : '0';
  }
}
