export const TOKEN: { [key: string]: string } = {
  JWT: 'jwt',
  EXPIRING_AT: 'jwtExp',

  SUCCESS_REDIRECT_URL: 'jwtSRU',
  SUCCESS_WINDOW_SHOW: 'jwtSWS',
  FAILURE_REDIRECT_URL: 'jwtFRU',
  FAILURE_WINDOW_SHOW: 'jwtFWS',
  ERROR_REDIRECT_URL: 'jwtERU',
  ERROR_WINDOW_SHOW: 'jwtEWS',

  EXTERNAL_JWT: 'jwtExt',
  EXTERNAL_EXPIRING_AT: 'jwtExtExp',
  EXTERNAL_SUCCESS_REDIRECT_URL: 'jwtExtSRU',
  EXTERNAL_FAILURE_REDIRECT_URL: 'jwtExtFRU',
  EXTERNAL_ERROR_REDIRECT_URL: 'jwtExtERU',
};
